<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="searchForm"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="triggerEvent">
          <TriggerEvent v-model="query.triggerEvent"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="goSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ total }}</span
        >条信息
        </div>
        <div class="op-extra">
<!--          <icon-button icon="share" title="导出" @click.native="exportData"/>-->
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="eventList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="事件名称" prop="couponEventName">
          </el-table-column>
          <el-table-column label="次数限制" prop="numberLimit">
          </el-table-column>
          <el-table-column label="金额限制" prop="moneyLimit">
          </el-table-column>
          <el-table-column label="事件Key" prop="couponEventKey">
          </el-table-column>
          <el-table-column label="描述" prop="couponEventDesc">
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="pageNo"
              :page-size="pageSize"
              :page-sizes="[10, 15, 30, 50]"
              :total="total"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TriggerEvent from "@/components/TriggerEvent";
import {getCouponEventForPage} from "@/api/coupon";
import {doExport} from "@/api/common";

export default {
  name: "event-manage",
  data() {
    return {
      query: {
        triggerEvent: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      eventList: [],
      isLoading: false,
    };
  },
  components: {
    TriggerEvent,
  },
  methods: {
    //重置查询条件
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.goSearch();
    },
    goSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.search();
    },
    search() {
      const eventId = this.query.triggerEvent;
      const page = this.pageNo;
      const size = this.pageSize;
      const isExport = this.query.isExport;
      const exportSize = this.query.exportSize;
      this.isLoading = true;
      getCouponEventForPage(page, size, eventId, isExport, exportSize).then(
          (res) => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.eventList = res.returnObject.records;
              this.total = res.returnObject.total;
              this.form.exportSize = res.returnObject.total;
            }
          }
      );
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = {
        eventId: this.query.triggerEvent,
        page: this.pageNo,
        size: this.pageSize,
        isExport: "1",
        exportSize: this.form.exportSize,
      };
      doExport(
          "/couponController/getCouponEventForPage",
          params,
          "优惠券事件管理.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.search();
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.search();
    },
  },
  mounted() {
    this.goSearch();
  },
};
</script>

<style lang="less" scoped>
</style>
